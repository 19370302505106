<template>
    <div class="studyMap">
        <div class="studyMap-con">
            <div @click="goToLink(item.name,n)" class="studyMap-list " :class="item.learn_rate==1?'succ list'+(n+1):item.learn_rate>0?'on list'+(n+1):'list'+(n+1)" v-for="(item,n) in navList" :key="n">
                <p>{{item.name}}</p>
                <p>({{item.learn_rate*100 || 0}}%)</p>
            </div>
            <div :class="list[0]" class="studyMap-arrow arrow1-1 after"><van-icon name="arrow" /></div>
            <div :class="list[0]" class="studyMap-arrow arrow1-2"><van-icon name="arrow" /></div>
            <div :class="list[0]" class="studyMap-arrow arrow1-3 after"><van-icon name="arrow" /></div>
            <div :class="list[0]" class="studyMap-arrow arrow1-4 after"><van-icon name="arrow" /></div>
            <div :class="list[0]" class="studyMap-arrow arrow1-5 after"><van-icon name="arrow" /></div>

            <div :class="list[1]" class="studyMap-arrow arrow2-1"><van-icon name="arrow-left" /></div>
            <div :class="list[1]" class="studyMap-arrow arrow2-2 before"><van-icon name="arrow-left" /></div>
            <div :class="list[1]" class="studyMap-arrow arrow2-3 before"><van-icon name="arrow-left" /></div>
            <div :class="list[1]" class="studyMap-arrow arrow2-4 before"><van-icon name="arrow-left" /></div>

            <div :class="list[2]" class="studyMap-arrow arrow3-1"><van-icon name="arrow-down" /></div>

            <div :class="list[3]" class="studyMap-arrow arrow4-1"><van-icon name="arrow-down" /></div>

            <div :class="list[4]" class="studyMap-arrow arrow5-1"><van-icon name="arrow-down" /></div>

        </div>
        <div class="studyMap-txt">
            箭头连线代表知识点依赖关系 <br>
            如学习二次型需要特征值和矩阵相关知识
        </div>
    </div>
</template>

<script>
    export default {
        name:'studyMap',
        data() {
            return {
                list: []
            }
        },
        props: {
            navList: {
                type: Array,
                default: []
            },
        },
        created () {
            this.$store.commit('getNavList');
            // this.$store.commit('jindu_query');
        },
        methods: {
            goToLink(name,n){
                this.$router.push({path:'/guide',query:{active:n}});
            },
            getPercent(){
                let navList = this.navList;
                if(navList){
                    navList.map((e,n)=>{
                        if(e.learn_rate==1){
                            this.list[n] = 'succ';
                        }else if(e.learn_rate>0){
                            this.list[n] = 'on';
                        }else{
                            this.list[n] = '';
                        }
                    })
                }else{
                    setTimeout(() => {
                        this.getPercent();
                    }, 1000);
                }
                console.log('this.list',this.list);
            }
        },
        mounted () {
            this.getPercent();
        },
    }
</script>

<style lang="less" scoped>
    .studyMap{
        padding: 16px 0;
        &-con{
            position: relative;
            height: 325px;
        }
        &-txt{
            height: 34px;
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #BFC1CC;
            line-height: 17px;
            text-align: center;
        }
        &-list{
            position: absolute;
            height: 50px;
            padding: 5px 8px;
            min-width: 58px;
            display: inline-block;
            background: #F4F6FD;
            border-radius: 2px;
            border: 1px solid #415FD5;
            box-sizing: border-box;
            p{
                font-size: 14px;
                font-family: MicrosoftYaHei;
                color: #415FD5;
                line-height: 19px;
                text-align: center;
            }
            &.on{
                border: 1px solid #415FD5;
                background: #415FD5;
                border-radius: 2px;
                p{
                    color: #fff;
                }
            }
            &.succ{
                border: 1px solid #15C392;
                background: #15C392;
                border-radius: 2px;
                p{
                    color: #fff;
                }
            }
            &.list1{
                top: 0px;
                left: 0px;
            }
            &.list2{
                right: 0px;
                top: 0px;
            }
            &.list3{
                top: 40px;
                left: 50%;
                transform: translate(-50%,0);
            }
            &.list4{
                top: 112px;
                left: 50%;
                transform: translate(-50%,0);
            }
            &.list5{
                top: 186px;
                left: 50%;
                transform: translate(-50%,0);
            }
            &.list6{
                top: 260px;
                left: 50%;
                transform: translate(-50%,0);
            }
        }
        .arrow{
            display: inline-block;
        }
        &-arrow{
            position: absolute;
            &.succ{
                border-color: #15C392 !important;
                .van-icon{
                    color: #15C392 !important;
                }
            }
            &.on{
                border-color: #415FD5 !important;
                .van-icon{
                    color: #415FD5 !important;
                }
            }
            .van-icon{
                font-size: 12px;
                color: #B9BFD3;
            }
            .van-icon-arrow-down{
                left: 50%;
                transform: translateX(-50%);
                bottom: -9px;
            }
            &.arrow1-1{
                background: #B9BFD3;
                height: 1px;
                width: 152px;
                top: 25px;
                left: 59px;
            }
            &.after{
                .van-icon{
                    position: absolute;
                    right: -4px;
                    // top: -6px;
                    top: 50%;
                    transform: translateY(-50%);
                }
                &.on{
                    background: #415FD5;
                }
                &.succ{
                    background: #15C392;
                }
            }
            &.before{
                .van-icon{
                    position: absolute;
                    left: -5px;
                    top: 50%;
                    transform: translateY(-50%);
                    color: #B9BFD3;
                }
                &.on{
                    background: #415FD5;
                }
                &.succ{
                    background: #15C392;
                }
            }
            &.arrow1-2{
                height: 236px;
                width: 70px;
                left: 29px;
                top: 50px;
                border-left: 1px solid #B9BFD3;
                border-bottom: 1px solid #B9BFD3;
                .van-icon{
                    position: absolute;
                    // bottom: -7px;
                    right: -5px;
                    // right: 0px;
                    bottom: -0.5px;
                    &::before{
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%);
                        right: 0;
                    }
                }
            }
            &.arrow1-3{
                width: 61px;
                left: 29px;
                top: 138px;
                background: #B9BFD3;
                height: 1px;
            }
            &.arrow1-4{
                width: 72px;
                left: 29px;
                top: 64px;
                background: #B9BFD3;
                height: 1px;
            }
            &.arrow1-5{
                width: 38px;
                left: 29px;
                top: 211px;
                background: #B9BFD3;
                height: 1px;
            }
            &.arrow2-1{
                width: 72px;
                height: 236px;
                right: 28px;
                top: 50px;
                border-right: 1px solid #B9BFD3;
                border-bottom: 1px solid #B9BFD3;
                .van-icon{
                    position: absolute;
                    left: -5px;
                    bottom: -0.5px;
                    &::before{
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%);
                        left: 0;
                    }
                }
            }
            &.arrow2-2{
                width: 76px;
                background: #B9BFD3;
                height: 1px;
                right: 28px;
                top: 64px;

            }
            &.arrow2-3{
                width: 61px;
                background: #B9BFD3;
                height: 1px;
                right: 28px;
                top: 136px;
            }
            &.arrow2-4{
                width: 40px;
                background: #B9BFD3;
                height: 1px;
                right: 28px;
                top: 211px;
            }
            &.arrow3-1{
                height: 22px;
                width: 1px;
                top: 89px;
                left: 136px;
                background: #B9BFD3;
                &.on{
                    background: #415FD5;
                }
                &.succ{
                    background: #15C392;
                }
            }
            &.arrow4-1{
                height: 22px;
                width: 1px;
                top: 162px;
                left: 136px;
                background: #B9BFD3;
                &.on{
                    background: #415FD5;
                }
                &.succ{
                    background: #15C392;
                }
            }
            &.arrow5-1{
                height: 22px;
                width: 1px;
                top: 238px;
                left: 136px;
                background: #B9BFD3;
                &.on{
                    background: #415FD5;
                }
                &.succ{
                    background: #15C392;
                }
            }
        }
    }
</style>